import styled from "styled-components"

import tw from 'twin.macro'

export default styled.div`

    .ais-Hits-list {
        ${tw`divide-y`}
    }

  .ais-Pagination--noRefinement {
    display: none;
  }

  .ais-Pagination {
    ${tw`pt-4 border-t mt-4 border-gray-200`}
  }
  .ais-Pagination-list {
    ${tw`flex justify-center gap-2 text-lg mb-4`}
  }
  .ais-Pagination-item--previousPage {
    ${tw`mr-auto`}
  }
  .ais-Pagination-item--nextPage {
    ${tw`ml-auto`}
  }
  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--nextPage {
    a {
      ${tw`text-violet`}
    }
  }
  .ais-Pagination-item--disabled {
    opacity:0 !important;
  }

  .ais-Hits-item {
      ${tw`py-4`}

  }

  .ais-SortBy-select {
    ${tw`border-0 py-0 ring-0`}
  }

  .ais-Pagination-link--selected {
    ${tw`font-black`}
  }
  
  .ais-Snippet-highlighted,
  .ais-Highlight-highlighted {
    ${tw`bg-yellow-golden text-violet`}
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }

    path[fill="#FFFFFF"] {
      ${tw`fill-current`}
    }
  }
`