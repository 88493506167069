import React from "react";
import { Link } from '@3nvi/gatsby-theme-intl';

import { VscChevronLeft } from "react-icons/vsc"
import { VscChevronRight } from "react-icons/vsc"

export const NextPage = () => {
    return (
      <div className="text-brand-green text-sm flex gap-1 items-center uppercase tracking-wider">Next <VscChevronRight /></div>
    ) 
}

export const PreviousPage = () => {
    return (
      <div className="text-brand-green text-sm flex gap-1 items-center uppercase tracking-wider"><VscChevronLeft /> Previous</div>
    ) 
}
  
const Pagination = ({ prevUrl, nextUrl, totalPages, currentPage, base, className='' }) => {

    return (
        <nav className={`flex justify-center gap-2 text-lg ${className}`} role="navigation" aria-label="Pagination Navigation">

        <Link to={prevUrl} 
            className={`text-brand-green mr-auto ${prevUrl ? '' : 'pointer-events-none opacity-0'}`}>
            <PreviousPage />
        </Link>
        {Array.from({ length: totalPages }).map((_, i) => (
            <Link
                className={`px-1 ${currentPage === 1 && i === 0 ? 'font-black' : ''}`}
                to={`${base}${i > 0 ? '/p':''}${i > 0 
                    ? i + 1 
                    : ''}`}
                key={`page${i}`}
            >
            {i + 1}
            </Link>
        ))}

        <Link to={nextUrl} 
            className={`text-brand-green flex gap-1 items-center uppercase tracking-wider ml-auto ${nextUrl ? '' : 'pointer-events-none opacity-0'}`}>
            <NextPage />
        </Link>
    </nav>
    );
}

export default Pagination;
