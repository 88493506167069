import React, { useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import SearchIcon from "../../images/search.svg";

export default connectSearchBox(({ refine, currentRefinement, className, onFocus }) => {

  const [searchState, setSearchState] = useState("");

    return (
      <label htmlFor="search-box" className={`block col-span-full mb-3 ${className}`}>
        <span className="sr-only">Search</span>
        <div className="relative">
          <form className="flex" onSubmit={e => { 
              e.preventDefault(); 
              if (searchState) refine(searchState) 
            }}
          >
            <input 
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={e => setSearchState(e.target.value)}
              onFocus={onFocus}
              className="w-full border-0 bg-gray-100 px-12 pr-4 focus:ring-0"
              id="search-box"
            />
            <button type="submit" className="hidden lg:inline-block bg-brand-green px-4 text-lg text-white tracking-wider md:tracking-widest uppercase">Search</button>
          </form>
          <img alt="search icon" src={SearchIcon} className="select-none absolute left-4 top-1/2 transform -translate-y-1/2 h-5"/>
        </div>
      </label>
    );
  }
);