import React, { useState } from "react";
import { navigate } from "@reach/router";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { BackgroundStyles } from "../styles/BackgroundStyles";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Hits, Pagination, Configure, connectStateResults } from "react-instantsearch-dom";
import PageHit from "../components/refinement/PageHit";
import SearchBox from "../components/refinement/SearchBox";
import SearchPageResultStyles from "../styles/SearchPageResultStyles";
import { NextPage, PreviousPage } from "../components/search/Pagination";
import {createURL, urlToSearchState } from "../utils/createUrl";

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);

const SearchIndex = ({location }) => {

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);
    setDebouncedSetState(setTimeout(() => { navigate(`${location.pathname}${createURL(updatedSearchState)}`, {state: updatedSearchState})}, DEBOUNCE_TIME));
    setSearchState(updatedSearchState);
  };

  const Results = connectStateResults(({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? children : <div className="py-4">No results found.</div>
  );

  return (
    <Layout sidebar={true}>
      <Seo title="Search" />
      <section className="section-inside">
        <div className="shell">
          <header className="section__head">
            <h1 className="section__title title h2 text-gray">Search</h1>
          </header>
        </div>
        <div className="w-full sm:container mx-auto px-4 h-full lg:flex">
          <BackgroundStyles className="shell">
            <div className="relative pb-8">
              <InstantSearch searchClient={searchClient} indexName="Global" searchState={searchState} onSearchStateChange={onSearchStateChange} createURL={createURL}>
                <Configure hitsPerPage={8}/>
                <SearchBox/>
                
                  {searchState.query &&
                    <SearchPageResultStyles>
                      <Results>
                        <Hits hitComponent={PageHit}/>
                      </Results>
                      <Pagination showFirst={false} showLast={false} translations={{previous: (<PreviousPage />), next: (<NextPage />)}}/>
                    </SearchPageResultStyles>
                  }      
              </InstantSearch>
            </div>
          </BackgroundStyles>
        </div>
      </section>
    </Layout>
  );
}

export default SearchIndex;