import React from "react"
import { connectHighlight } from 'react-instantsearch-dom';

const Snippet = ({ highlight, attribute, hit, className}) => {
  const parsedHit = highlight({
    highlightProperty: '_snippetResult',
    attribute,
    hit,
  });

  return (
    <span className={className}>
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <mark key={index} dangerouslySetInnerHTML={{
                __html: part.value
            }} />
          ) : (
            <span key={index} dangerouslySetInnerHTML={{
                __html: part.value
            }} />
          )
      )}
    </span>
  );
};

const CustomSnippet = connectHighlight(Snippet);

export default CustomSnippet