import React from "react";
import { Link } from "@3nvi/gatsby-theme-intl";
import { Highlight } from "react-instantsearch-dom";
import CustomSnippet from "../CustomSnippet";

const PageHit = ({ hit }) => {

  const array = { "whatWeDo": "What We Do", "projects": "Projects", "newsletters": "Newsletters", "news": "News", "page": "Page", "resources": "Reports and other documents" }; 

  function mapHandle(handle){

    for (var key in array) {
      if(key === handle) return array[key];
    }
  }

  const translate = mapHandle(hit.typeHandle);

  return (
    <div className="leading-tight relative">
      <Link to={`/${hit.uri}`} >
        <span className="p-1 bg-brand-green mb-2 text-xs  text-white tracking-wide leading-none inline-block">{translate}</span>
        <h4 className="font-black font-display mb-1 text-xl">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
        <CustomSnippet attribute="summary" hit={hit} tagName="mark" className="text-md line-clamp-2"/>
      </Link>
    </div>
  );
}

export default PageHit;